import request from 'utils/request';
const api = {
  // information
  INFORMATION: '/api/source/detail/information',
  OFFER_COUNTRIES: '/api/source/detail/offerCountries',
  // source setting
  SOURCE_SETTING: '/api/source/detail/sourceSetting',
  ADD_SETTING: '/api/source/detail/addSetting',
  // adjust rate
  ADJUST_RATE_LIST: '/api/source/detail/adjustRateList',
  ADD_JUST_RATE: '/api/source/detail/addAdjustRate',
  DELETE_JUST_RATE: '/api/source/detail/deleteAdjustRate',
  // aff write list
  AFF_WHITE_LIST: '/api/source/detail/affWhiteList',
  ADD_AFF_WHITE_LIST: '/api/source/detail/addAffWhiteList',
  DELETE_AFF_WHITE_LIST: '/api/source/detail/deleteAffWhiteList',
  // aff allow
  AFF_ALLOW_LIST: '/api/source/detail/affAllowList',
  // aff sub white
  AFF_SUB_WHITE_LIST: '/api/source/detail/affSubWhiteList',
  ADD_AFF_SUB_WHITE_LIST: '/api/source/detail/addAffSubWhiteList',
  DELETE_AFF_SUB_WHITE_LIST: '/api/source/detail/deleteAffSubWhiteList',
  // aff sub blacklist
  AFF_SUB_BLACKLIST: '/api/source/detail/affSubBlackList',
  ADD_AFF_SUB_BLACKLIST: '/api/source/detail/addAffSubBlackList',
  DELETE_AFF_SUB_BLACKLIST: '/api/source/detail/deleteAffSubBlackList',
  // aff black
  AFF_BLACKLIST: '/api/source/detail/affBlackList',
  ADD_AFF_BLACKLIST: '/api/source/detail/addAffBlackList',
  DELETE_AFF_BLACKLIST: '/api/source/detail/deleteAffBlackList',

  // decrypt
  DECRYPT_AFF_SUB: '/api/source/detail/decryptAffSub',
};

// information
export function getInformation(query) {
  return request({
    url: api.INFORMATION,
    method: 'get',
    params: query,
  });
}
export function getOfferCountreies(sourceId) {
  return request({
    url: api.OFFER_COUNTRIES,
    method: 'get',
    params: {
      sourceId,
    },
  });
}
// source setting

export function getSourceSetting(sourceId) {
  return request({
    url: api.SOURCE_SETTING,
    method: 'get',
    params: {
      sourceId,
    },
  });
}
export function addSourceSetting(data) {
  return request({
    url: api.ADD_SETTING,
    method: 'post',
    data,
  });
}
// adjust rate
export function getAdjustRateList(sourceId) {
  return request({
    url: api.ADJUST_RATE_LIST,
    method: 'get',
    params: {
      sourceId,
    },
  });
}
export function addAdjustRate(data) {
  return request({
    url: api.ADD_JUST_RATE,
    method: 'post',
    data,
  });
}
export function deleteAdjustRate(query) {
  return request({
    url: api.DELETE_JUST_RATE,
    method: 'DELETE',
    params: query,
  });
}
// aff write list
export function getAffWhiteList(query) {
  return request({
    url: api.AFF_WHITE_LIST,
    method: 'get',
    params: query,
  });
}
export function addAffWhiteList(data) {
  return request({
    url: api.ADD_AFF_WHITE_LIST,
    method: 'post',
    data,
  });
}
export function deleteAffWhiteList(query) {
  return request({
    url: api.DELETE_AFF_WHITE_LIST,
    method: 'DELETE',
    params: query,
  });
}
// aff allow
export function getAffAllowList(query) {
  return request({
    url: api.AFF_ALLOW_LIST,
    method: 'get',
    params: query,
  });
}
// aff sub white list
export function getAffSubWhiteList(query) {
  return request({
    url: api.AFF_SUB_WHITE_LIST,
    method: 'get',
    params: query,
  });
}
export function addAffSubWhiteList(data) {
  return request({
    url: api.ADD_AFF_SUB_WHITE_LIST,
    method: 'post',
    data,
  });
}
export function deleteAffSubWhiteList(query) {
  return request({
    url: api.DELETE_AFF_SUB_WHITE_LIST,
    method: 'delete',
    params: query,
  });
}

// aff sub blacklist
export function getAffSubBlacklist(query) {
  return request({
    url: api.AFF_SUB_BLACKLIST,
    method: 'get',
    params: query,
  });
}

export function addAffSubBlackList(data) {
  return request({
    url: api.ADD_AFF_SUB_BLACKLIST,
    method: 'post',
    data,
  });
}
export function deleteAffSubBlackList(query) {
  return request({
    url: api.DELETE_AFF_SUB_BLACKLIST,
    method: 'delete',
    params: query,
  });
}

// aff blacklist
export function getAffBlacklist(query) {
  return request({
    url: api.AFF_BLACKLIST,
    method: 'get',
    params: query,
  });
}

export function addAffBlackList(data) {
  return request({
    url: api.ADD_AFF_BLACKLIST,
    method: 'post',
    data,
  });
}
export function deleteAffBlackList(query) {
  return request({
    url: api.DELETE_AFF_BLACKLIST,
    method: 'delete',
    params: query,
  });
}
export function decryptAffSub(affSubConfusion) {
  return request({
    url: api.DECRYPT_AFF_SUB,
    method: 'post',
    data: {
      affSubConfusion,
    },
  });
}
