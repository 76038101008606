<template>
  <div>
    <el-form :model="addSetting" label-width="160px" size="mini">
      <el-row>
        <el-col :span="6">
          <el-form-item label="Account Daily Cap" label-width="140px">
            <el-input
              v-model="addSetting.accountCapDaily"
              placeholder="write account daily cap.."
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Conversion Daily Cap">
            <el-input
              v-model="addSetting.conversionCapDaily"
              placeholder="write conversion daily cap.."
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="All Offer Cap" label-width="140px">
            <el-input
              v-model="addSetting.sourceOfferCap"
              placeholder="write cap for all source offer.."
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Click Daily Cap" label-width="130px">
            <el-input
              v-model="addSetting.clickCapDaily"
              placeholder="write Click Daily Cap"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Offer Redirect Test" label-width="140px">
            <el-input type="textarea" :rows="2" v-model="addSetting.description"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" size="mini" @click="addSourceSetting">confirm</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="AffSub Decryption Confusion" label-width="200px">
            <el-input
              v-model="decryptAffSubVal"
              placeholder="write confusion affsub"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" type="primary" @click="decryptAffSub">Decrypt</el-button>
        </el-col>
        <el-col :span="8">
          <!-- 上传 -->
          <el-form-item>
            <form
              action="/api/source/detail/decryptExcel"
              target="_blank"
              method="post"
              enctype="multipart/form-data"
            >
              <input
                style="width:180px;margin-left: 5px;"
                @change="getFile"
                name="file"
                type="file"
              />
              <button type="submit" class="btn btn-primary">Download Excel</button>
            </form>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { addSourceSetting, getSourceSetting, decryptAffSub } from 'api/source/detail';
  const defaultSetting = {
    sourceId: '',
    accountCapDaily: '',
    conversionCapDaily: '',
    description: '',
    sourceOfferCap: '',
    clickCapDaily: '',
  };
  export default {
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        decryptAffSubVal: '',
        addSetting: Object.assign({}, defaultSetting),
      };
    },
    mounted() {
      this.getSourceSetting();
    },
    methods: {
      getSourceSetting() {
        getSourceSetting(this.id).then((response) => {
          if (response.code === 200) {
            this.addSetting = response.result ?? Object.assign({}, defaultSetting);
          }
        });
      },
      addSourceSetting() {
        addSourceSetting({ ...this.addSetting, sourceId: this.id }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
          } else {
            this.getSourceSetting();
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      decryptAffSub() {
        if (!this.decryptAffSubVal) {
          this.$message.warning('要先输入待解析的内容哦~');
        }
        decryptAffSub(this.decryptAffSubVal).then((response) => {
          if (response.code === 200) {
            this.decryptAffSubVal = response.result;
          } else {
            this.$message.error('解析字符串失败啦');
          }
        });
      },
      getFile() {
        this.$message({
          message: '子渠道放在第一列',
          type: 'warning',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-primary {
    width: 120px;
    height: 35px;
    line-height: 35px;
    margin-left: 60px;
    border-radius: 4px;
    background: #409eff;
    color: #fff;
    border-color: #409eff;
  }
</style>
