<template>
  <div class="source_detail">
    <div class="mainBox">
      <div class="main-Title bgff mb10">
        <h2>
          Source Detail
          <span class="pl20">
            SourceId -
            <span>{{ sourceId }}</span>
          </span>
          <span class="pl20">
            SourceName -
            <span>{{ sourceName }}</span>
          </span>
        </h2>
      </div>
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="Information" name="first" lazy>
          <information :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane label="System Setting" name="second" lazy>
          <system-setting :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane label="Adjust Rate Setting" name="third" lazy>
          <adjust-rate-setting :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane label="Affiliate WhiteList Message" name="fourth" lazy>
          <aff-white-list :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane
          label="Allow Access Affiliate Message （Not in affiliate blacklist）"
          name="fifth"
          lazy
        >
          <allow-access-aff :id="sourceId" lazy />
        </el-tab-pane>
        <el-tab-pane label="AffSub WhiteList Message" name="sixth" lazy>
          <aff-sub-white-list :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane label="AffSub Blacklist Message" name="eighth" lazy>
          <aff-sub-blacklist :id="sourceId" />
        </el-tab-pane>
        <el-tab-pane label="Affiliate Blacklist Message" name="ninth" lazy>
          <aff-blacklist :id="sourceId" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { myMixin } from '@/mixins/mixins.js';
  import Information from './components/Information';
  import SystemSetting from './components/SystemSetting';
  import AdjustRateSetting from './components/AdjustRateSetting';
  import AffWhiteList from './components/AffWhiteList';
  import AllowAccessAff from './components/AllowAccessAff';
  import AffSubWhiteList from './components/AffSubWhiteList';
  import AffSubBlacklist from './components/AffSubBlacklist';
  import AffBlacklist from './components/AffBlacklist';
  export default {
    mixins: [myMixin],
    components: {
      Information,
      SystemSetting,
      AdjustRateSetting,
      AffWhiteList,
      AllowAccessAff,
      AffSubWhiteList,
      AffSubBlacklist,
      AffBlacklist,
    },
    data() {
      return {
        activeName: 'first',
        sourceId: this.$route.query.sourceId,
        sourceName: this.$route.query.sourceName,
      };
    },
  };
</script>
