<template>
  <div>
    <el-form :model="information" label-width="90px" size="small">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="TimeZone">
            <el-select v-model="information.targetZone" class="w100">
              <el-option
                v-for="item in timezone"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Date" label-width="60px">
            <el-date-picker
              v-model="startToEndDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              class="w100"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="getinformationChart" size="mini">Search</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div id="informationChart">
      <el-col :lg="24" :sm="14" :xs="26">
        <div
          id="informationContainer"
          ref="informationContainer"
          style="weight:100%;height:560px;"
        ></div>
      </el-col>
      <el-col :lg="24" :sm="14" :xs="22">
        <div
          id="countriesContainer"
          ref="countriesContainer"
          style="weight:400px;height:560px;"
        ></div>
      </el-col>
      <div class="font-16">
        <el-col :span="8" align="center"> Offer total : {{ sourceOfferTotal }} </el-col>
        <el-col :span="8" align="center"> Unknown Domain Offer total : {{ unknownTotal }} </el-col>
        <el-col :span="8" align="center"> Total revenue : ${{ totalRevenue }} </el-col>
      </div>
    </div>
  </div>
</template>

<script>
  import dateUtils from '@/utils/dateutils';
  import echarts from '@/setup/echarts';
  import { getInformation, getOfferCountreies } from 'api/source/detail';
  export default {
    name: 'Information',
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        information: {
          targetZone: '+0:00',
        },
        sourceOfferTotal: '',
        unknownTotal: '',
        totalRevenue: '',
        startToEndDate: '',
        periodOptions: [
          {
            label: 'Within 7 days',
            value: 'Within 7 days',
          },
          {
            label: 'This week',
            value: 'This week',
          },
          {
            label: 'Last week',
            value: 'Last week',
          },
          {
            label: 'This month',
            value: 'This month',
          },
          {
            label: 'Last month',
            value: 'Last month',
          },
          {
            label: 'To day',
            value: 'Specified date',
          },
        ],
        timezone: [
          {
            label: '(GMT -12:00) Eniwetok, Kwajalein',
            value: '-12:00',
          },
          {
            label: '(GMT -11:00) Midway Island, Samoa',
            value: '-11:00',
          },
          {
            label: '(GMT -10:00) Hawaii',
            value: '-10:00',
          },
          {
            label: '(GMT -09:00) Alaska',
            value: '-9:00',
          },
          {
            label: '(GMT -08:00) Pacific Time(US & Canada)',
            value: '-8:00',
          },
          {
            label: '(GMT -07:00) Mountain Time(US & Canada)',
            value: '-7:00',
          },
          {
            label: '(GMT -06:00) Central Time(US & Canada), Mexico City',
            value: '-6:00',
          },
          {
            label: '(GMT -05:00) Eastern Time(US & Canada), Bogota, Lima',
            value: '-5:00',
          },
          {
            label: '(GMT -04:00) Atalantic Time(Canada), Caracas, La Paz',
            value: '-4:00',
          },
          {
            label: '(GMT -03:00) Brazil, Buenos Aires, Georgetown',
            value: '-3:00',
          },
          {
            label: '(GMT -02:00) Mid-Atlantic',
            value: '-2:00',
          },
          {
            label: '(GMT -01:00) Azores, Cape Verde Islands',
            value: '-1:00',
          },
          {
            label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
            value: '+0:00',
          },
          {
            label: '(GMT +01:00) Brussels, Copenhagen, Madrid, Paris',
            value: '+1:00',
          },
          {
            label: '(GMT +02:00) Kaliningrad, South Africa',
            value: '+2:00',
          },
          {
            label: '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg',
            value: '+3:00',
          },
          {
            label: '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi',
            value: '+4:00',
          },
          {
            label: '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
            value: '+5:00',
          },
          {
            label: '(GMT +06:00) Almaty, Dhaka, Colombo',
            value: '+6:00',
          },
          {
            label: '(GMT +07:00) Bangkok, Hanoi, Jakarta',
            value: '+7:00',
          },
          {
            label: '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong',
            value: '+8:00',
          },
          {
            label: '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
            value: '+9:00',
          },
          {
            label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
            value: '+10:00',
          },
          {
            label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
            value: '+11:00',
          },
          {
            label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
            value: '+12:00',
          },
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              },
            },
          ],
        },
      };
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getinformationChart();
      this.getOffercountriesChart();
    },
    methods: {
      drawInformationChart(data) {
        const domainList = data.domain;
        const domainTotal = data.domain_total;
        this.sourceOfferTotal = data.source_offer_total;
        const earningsList = data.earnings || [];
        this.totalRevenue = data.total_revenue;

        const domainData = new Array();
        const domainOfferData = new Array();
        for (let i = 0; i < domainTotal; i++) {
          domainData.push(domainList[i].pre_source);
          if (domainList[i].pre_source == 'UNKNOWN') {
            this.unknownTotal = domainList[i].offer_count;
          } else {
            domainOfferData.push({
              name: domainList[i].pre_source,
              value: domainList[i].offer_count,
            });
          }
        }

        const earningsData = new Array();
        console.log(earningsList);
        for (let i = 0; i < earningsList.length; i++) {
          earningsData.push({
            name: earningsList[i].pre_source,
            value: earningsList[i].payout_count,
          });
        }

        const myChart = echarts.init(this.$refs.informationContainer);
        myChart.showLoading();
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
          },
          series: [
            {
              name: 'domains',
              type: 'pie',
              radius: '25%',
              center: ['25%', '50%'],
              label: {
                normal: {
                  formatter: '{b}：{d}%',
                  backgroundColor: '#eee',
                },
              },
              data: domainOfferData,
            },
            {
              name: 'earnings',
              type: 'pie',
              radius: '25%',
              center: ['75%', '50%'],
              label: {
                normal: {
                  formatter: '{b}：{d}%',
                  backgroundColor: '#eee',
                },
              },
              data: earningsData,
            },
          ],
        });
        myChart.hideLoading();
      },
      drawOfferCountriesChart(data) {
        let myChart = echarts.init(this.$refs.countriesContainer);
        const countriesData = new Array();
        const selected = {};
        Object.keys(data).forEach((item, index) => {
          selected[item] = index < 20;
          countriesData.push({
            name: item,
            value: data[item],
          });
        });

        myChart.showLoading();
        myChart.setOption({
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            selected,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              center: ['50%', '50%'],
              label: {
                normal: {
                  formatter: '{b}：{d}%',
                  backgroundColor: '#eee',
                },
              },
              data: countriesData,
            },
          ],
        });
        myChart.hideLoading();
      },
      getinformationChart() {
        let param = {
          ...this.information,
        };
        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param['fromDate'] = startDate;
          param['toDate'] = endDate;
        }
        param['sourceId'] = this.id;
        getInformation(param).then((response) => {
          if (response.code == 200) {
            this.drawInformationChart(response.result);
          }
        });
      },
      getOffercountriesChart() {
        getOfferCountreies(this.id).then((response) => {
          if (response.code === 200) {
            this.drawOfferCountriesChart(response.result);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #informationChart {
    width: 100%;
    background: $base-white;
    margin-top: 20px;
    -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
  }
</style>
